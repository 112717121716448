<template>
	<div class="banner" v-if="banner">
		<section class="section banner__inner">
			<div class="has-text-centered">
				<markdown-it-vue :content="banner.titlu" />
			</div>
		</section>
		<banner-carousel :slides="slides" v-if="slides && slides.length > 1" />
		<div class="banner__image is-relative" v-else>
			<img
				:src="`${databaseLink}/${banner.imagine.url}`"
				:alt="$route.name"
			/>
		</div>

		<section class="section banner__content has-text-centered">
			<markdown-it-vue :content="banner.descriere" />
		</section>
	</div>
</template>

<script>
	import BannerCarousel from '@/components/TheBannerCarousel.vue';

	export default {
		props: {
			banner: {
				type: Object,
				require: true,
			},
			slides: {
				type: Array,
				require: true,
			},
		},
		name: 'SecondaryBanner',
		components: {
			BannerCarousel,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			formatBannerRouteName() {
				let str = this.$route.name.toLowerCase();
				let charMap = {
					ă: 'a',
					â: 'a',
					î: 'i',
					ș: 's',
					ț: 't',
				};
				let rx = /(ă|â|î|ș|ț)/g;
				if (rx.test(str)) {
					str = str.replace(rx, function(m, key) {
						return charMap[key];
					});
				}
				str = str.replace(/[^a-z\d\s-]/gi, '');
				str = str.replace(/[\s-]+/g, ' ');
				str = str.replace(/\s/g, '-');
				return str;
			},
		},
	};
</script>

<style scoped lang="scss">
	.banner {
		background-color: lighten($gray, 28%);
		&__title {
			font-size: 1.25rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align: center;
			@media (min-width: 481px) {
				font-size: 1.5rem;
			}
			@media (min-width: 769px) {
				font-size: 1.75rem;
			}
		}
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: auto;
			}
		}
		strong {
			color: $primary;
			font-weight: $semibold;
		}
	}
</style>
